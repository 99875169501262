import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from "./ErrorPage";
import PrivacyPolicy from './PrivacyPolicy';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2OyMAL2U9Xm1JZJ8UPuhapwC5MZw7bLI",
  authDomain: "runright-d8222.firebaseapp.com",
  projectId: "runright-d8222",
  storageBucket: "runright-d8222.appspot.com",
  messagingSenderId: "813693907644",
  appId: "1:813693907644:web:fdec1c00e215e47593d6b9",
  measurementId: "G-PW3T4HCPN0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();